import { render, staticRenderFns } from "./chatbot.vue?vue&type=template&id=3f0fc358&scoped=true&"
import script from "./chatbot.vue?vue&type=script&lang=js&"
export * from "./chatbot.vue?vue&type=script&lang=js&"
import style0 from "./chatbot.vue?vue&type=style&index=0&id=3f0fc358&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f0fc358",
  null
  
)

export default component.exports