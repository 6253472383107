import Vue from "vue";
import Chatbot from "@/src/components/chatbots/chatbot.vue";

export default function chatbotFirst() {
  document.addEventListener("DOMContentLoaded", () => {
    const el = document.createElement("div");
    document.body.appendChild(el);

    new Vue({
      render: (h) => h(Chatbot),
    }).$mount(el);
  });
}
